import styled, { css } from "styled-components"

export const IllustrationWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;

  ${(props: { size: string }): any =>
    props.size === `small` &&
    css`
      height: 100vh;
      z-index: -1;
      opacity: 0.2;
    `}
`
