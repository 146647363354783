/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import { Box, Text } from "grommet"
import { PageComponentsProps, Questions } from "../../../types/interface"
import Services from "../../pages/services"
import useView from "../../../hooks/view"
import FadeIn from "../../_shared_/fade-in"

interface QAProps extends PageComponentsProps {
  data: Questions[]
  filterOutId?: number
}

const QA: React.FC<QAProps> = ({ size, data, filterOutId }) => {
  const { refItem, wasInView } = useView()

  return (
    <Box ref={refItem}>
      {wasInView && (
        <>
          <Box justify="center">
            {data.map(({ question, answer }, index) => (
              <FadeIn delay={(index / 2) * 100} key={index}>
                <Box margin={{ bottom: `32px` }} className="border-action">
                  <Box direction="row" align="center">
                    <Text size="large" weight="bold" margin={{ bottom: `12px` }}>
                      {question}
                    </Text>
                  </Box>
                  <Text size="medium">{answer}</Text>
                </Box>
              </FadeIn>
            ))}
          </Box>
          <Services filterOutId={filterOutId} mt={`0`} size={size} />
        </>
      )}
    </Box>
  )
}

export default QA
