/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react"
import { Box, Grid, Heading, Paragraph, Text } from "grommet"
import styled from "styled-components"
import Trail from "../_shared_/trail"
import FadeIn from "../_shared_/fade-in"
import AccountingIllustration from "./components/accounting.illustration"
import { Buttons } from "../../styles/components/button-effect"
import { SpringLink } from "../_shared_/transition"
import ScrollContext from "../../context/scroll-context"
import { mainBlack } from "../../styles/constants"
import Cloud from "../../images/cloud.svg"

const CloudBox = styled.div`
  position: absolute;
  width: 80%;
  top: 10%;
  left: -20%;
  z-index: 0;
  transform: rotate(130deg);
  opacity: 0.5;
`

const AccountingLayer: React.FC<{ size: string }> = ({ size }) => {
  const context = useContext(ScrollContext)
  const desktop = size !== `small`

  return (
    <Grid columns={desktop ? [`50%`, `50%`] : [`100%`]} rows="fit" gap="small">
      <Box>
        <Heading size="medium" margin={{ bottom: `8px` }}>
          {desktop && (
            <>
              <Trail text="Účtovníctvo a" />
              <Trail text="poradenstvo" />
            </>
          )}
          {!desktop && `Účtovníctvo a poradenstvo`}
        </Heading>
        <Paragraph margin={{ top: `0px`, bottom: `30px` }} size="large" style={{ opacity: 0.6 }} color={mainBlack}>
          pre každého
        </Paragraph>
        <FadeIn>
          <Text margin="0" size="large" weight="bold">
            Účtovníctvo
          </Text>
          <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
            Pre malé a stredné podniky ponúkame externé účtovné a daňové služby, čím podnikateľom dokážeme ušetriť až 70
            % nákladov na vedenie účtovníctva. Odovzdaním kompletnej agendy do rúk profesionálom si ušetríte kopu
            starostí a môžete sa plne sústrediť na Vaše podnikanie.
          </Paragraph>
          <Text margin="0" size="large" weight="bold">
            Poradenstvo
          </Text>
          <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
            Naši ekonómovia Vás včas upozornia na zákonné povinnosti, lehoty a legislatívne zmeny, ktoré sa Vás
            bezprostredne týkajú, čím minimalizujú riziko zbytočných pokút zo strany štátnych orgánov. Rovnako sú Vám k
            dispozícii v oblasti odborného poradenstva.
          </Paragraph>
          <Buttons mt={12}>
            <SpringLink to="/" onClick={(): void => context.handleSetScrollTo(`contact`)}>
                Vyskúšať zadarmo
            </SpringLink>
          </Buttons>
        </FadeIn>
      </Box>
      <AccountingIllustration size={size} />
      <CloudBox>
        <Cloud />
      </CloudBox>
    </Grid>
  )
}

export default AccountingLayer
