/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import { Box, ResponsiveContext } from "grommet"
import FooterLayout from "../components/_shared_/footer"
import { Transition } from "../components/_shared_/transition"
import HeaderLayout from "../components/_shared_/header"
import AccountingLayer from "../components/sub/accounting"
import { Container } from "../styles/components/container"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import QA from "../components/sub/components/qa"
import { Questions } from "../types/interface"
import Cookies from "../components/_shared_/cookies"

const seoDescription = `
 Odovzdaním účtovníctva do rúk profesionálom ušetríte až 70 % nákladov na jeho spracovanie, pričom sa môžete plne 
 sústrediť na Vaše podnikanie. Za férovú cenu u nás získate kvalitné služby a garanciu, že sa o Vaše účtovníctvo
 postaráme ako o svoje vlastné.
`

const accQA: Questions[] = [
  {
    question: `Je zmena účtovníka komplikovaná ?`,
    answer: `Nie, celý proces za Vás vybavíme my.`,
  },
  {
    question: `Je rozumné si hľadať účtovníka už pri začatí podnikania ?`,
    answer: `Rozhodne áno, čím skôr si vyberiete kvalitného účtovníka, tým väčší budete mať prehľad o svojich financiách.`,
  },
  {
    question: `Prečo by ste sa mali rozhodnúť pre nás ?`,
    answer: `Pretože sme flexibilní, na otázky klientov reagujeme okamžite a o zverené účtovníctvo sa staráme ako o svoje vlastné. A dôkazom sú spokojní klienti a ich odporúčania.`,
  },
]

const Accounting: React.FunctionComponent = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <GrommetWrapper>
      <SEO title="SlovAcc: Účtovníctvo pre každého | vyskúšajte 1. mesiac zadarmo" description={seoDescription} />
      <Cookies />
      <ResponsiveContext.Consumer>
        {(size: string): JSX.Element => (
          <>
            <HeaderLayout size={size} />
            <Transition>
              <Container>
                <Box
                  justify="center"
                  height={size !== `small` ? `100vh` : `100%`}
                  margin={{ top: size !== `small` ? `0px` : `93px`, bottom: size !== `small` ? `0px` : `93px` }}
                >
                  <AccountingLayer size={size} />
                </Box>
                <QA size={size} data={accQA} filterOutId={1} />
              </Container>
              <Container minHeight="auto" fluid>
                <FooterLayout size={size} />
              </Container>
            </Transition>
          </>
        )}
      </ResponsiveContext.Consumer>
    </GrommetWrapper>
  )
}

export default Accounting
